import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

const EARLIEST_DATE = new Date(-8640000000000000 / 2);
const LATEST_DATE = new Date(8640000000000000 / 2);

const normalize = {
  /**
   * Set the given date to the start of the specified time unit.
   * @memberof WunderDate
   * @alias normalizeFrom
   * @param {DateLike} date
   * @param {TimeUnit} what
   * @default what day
   * @return {DateLike} normalized date
   */
  from: function normalizeFrom(date, what) {
    return dayjs.utc(date).startOf(what || "day");
  },

  /**
   * Set the given date to the end of the specified time unit.
   * @memberof WunderDate
   * @alias normalizeTo
   * @param {DateLike} date
   * @param {TimeUnit} what
   * @default what day
   * @return {DateLike} normalized date
   */
  to: function normalizeTo(date, what) {
    return dayjs.utc(date).endOf(what || "day");
  },

  /**
   * Normalizes a date range to the specified time unit.
   * @memberof WunderDate
   * @alias normalizeBlock
   * @param {DateRange} block
   * @param {DateLike} block.from
   * @param {DateLike} block.to
   * @param {TimeUnit} what
   * @default what day
   * @return {DateRange} normalizedRange
   */
  block: function normalizeBlock(block, what) {
    return Object.assign({}, block, {
      from: normalize.from(block.from || EARLIEST_DATE, what),
      to: normalize.to(block.to || LATEST_DATE, what),
    });
  },

  /**
   * Normalizes an array of date ranges to the specified time unit.
   * @memberof WunderDate
   * @alias normalizeBlocks
   * @param {Array.<DateRange>}
   * @param {TimeUnit} what
   * @default what day
   * @return {Array.<DateRange>}
   */
  blocks: function normalizeBlocks(blocks, what) {
    what = what || "days";

    blocks = normalize.sortBlocks(blocks.map((b) => normalize.block(b, what)));

    return merge(blocks);

    function merge(blocks) {
      const normalized = [];

      while (blocks.length) {
        const block = blocks.shift();
        const nextBlock = blocks[0];

        // this is the last block
        if (!nextBlock) {
          normalized.push(block);
          break;
        }

        // everything ok
        // block [--------]
        // next            [--------]
        if (block.to < nextBlock.from) {
          normalized.push(block);
          continue;
        }

        // block [--------]
        // next       [--------]
        if (block.to < nextBlock.to) {
          // [-------------]
          block.to = nextBlock.to;
          normalized.push(block);
          // remove next block
          blocks.shift();
          continue;
        }

        // block [---------------]
        // next       [--------]
        if (block.to >= nextBlock.to) {
          // remove next block
          blocks.shift();
          // reevaluate block next iteration
          blocks.unshift(block);
        }
      }

      return normalized;
    }
  },

  /**
   * Sorts an array of date ranges.
   * @memberof WunderDate
   * @alias sortBlocks
   * @param {Array.<DateRange>} blocks date range that will be sorted
   * @return {Array.<DateRange>} sortedRange
   */
  sortBlocks: function sortBlocks(blocks) {
    return blocks.sort(byFrom);

    function byFrom(b1, b2) {
      return b1.from - b2.from;
    }
  },
};

export default normalize;
