import { MAX_PRICE_CAP } from "../components/Slider/algorithm";

export const isDefaultFilter = ({
  from,
  to,
  amenities,
  minRooms,
  minPrice,
  maxPrice,
  noDeposit,
  minSize,
  minAccommodates,
  petRules,
  smokingRules,
  totalSingleBeds,
  totalDoubleBeds,
  bbox,
  homeType,
  search,
  // Do not remove scoreVariant - permanent ranking A/B test
  scoreVariant,
}) => {
  return (
    !from &&
    !to &&
    !amenities &&
    !minPrice &&
    (!maxPrice || parseInt(maxPrice, 10) >= MAX_PRICE_CAP) &&
    !noDeposit &&
    (!minSize || parseInt(minSize, 10) === 1) &&
    (!minRooms || parseInt(minRooms, 10) === 1) &&
    (!totalSingleBeds || parseInt(totalSingleBeds, 10) === 0) &&
    (!totalDoubleBeds || parseInt(totalDoubleBeds, 10) === 0) &&
    (!minAccommodates || parseInt(minAccommodates, 10) === 1) &&
    (!petRules || petRules === "false") &&
    (!smokingRules || smokingRules === "false") &&
    (!homeType || homeType === "") &&
    !bbox &&
    !search &&
    // Do not remove scoreVariant - permanent ranking A/B test
    !scoreVariant
  );
};

export const triggerGAEvent = (filterName, filterValue, gaEvent) => {
  if (filterName === "amenities" && filterValue) {
    filterValue.split(",").forEach((amenity) => {
      gaEvent("amenities", amenity);
    });
  } else if (filterName === "minSize" && filterValue) {
    gaEvent("filter_set", `minSize_${filterValue}`);
  } else if (
    (filterName === "petRules" || filterName === "smokingRules") &&
    filterValue
  ) {
    gaEvent("filter_set", `houseRules_${filterName}`);
  } else if (
    (filterName === "totalSingleBeds" || filterName === "totalDoubleBeds") &&
    filterValue
  ) {
    gaEvent("filter_set", `minBeds_${filterName}`);
  } else if (
    filterName !== "maxPriceTemp" &&
    filterName !== "minPriceTemp" &&
    filterName !== "petRules" &&
    filterName !== "smokingRules" &&
    filterName !== "amenities" &&
    filterName !== "minSize" &&
    filterName !== "totalSingleBeds" &&
    filterName !== "totalDoubleBeds"
  ) {
    gaEvent("filter_set", filterName, filterValue);
  }
};

export const updateQuery = (previousQuery, nextQuery) => {
  // Replace empty queries with null, that's how we remove them from the URL
  let newQuery = Object.assign({}, previousQuery, nextQuery);
  newQuery = Object.keys(newQuery).reduce((acc, key) => {
    if (!newQuery[key] || isDefaultFilter({ [key]: newQuery[key] })) {
      acc[key] = null;
    } else {
      acc[key] = newQuery[key];
    }

    return acc;
  }, {});

  return newQuery;
};

const sanitizeMinAccommodates = (query) => {
  if (
    query.minAccommodates != null &&
    (isNaN(query.minAccommodates) || Number(query.minAccommodates) <= 0)
  ) {
    query.minAccommodates = "1";
  }
};

const sanitizeMinRooms = (query) => {
  if (query.minRooms != null && Number(query.minRooms > 20)) {
    query.minRooms = 20;
  }
};

export const sanitizeQuery = (query) => {
  const cleanQuery = Object.assign({}, query);

  sanitizeMinAccommodates(cleanQuery);
  sanitizeMinRooms(cleanQuery);

  return cleanQuery;
};
