import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import { getDisplayName } from "../utils/react-utils";
import getWFCONFIG from "../utils/WF_CONFIG";

const WF_CONFIG = getWFCONFIG();
const ENV = WF_CONFIG.NODE_ENV;

const isSecureCookie = ENV === "production" || ENV === "staging";

export const EXPERIMENT_COOKIE_KEY = "experiments";
export const EXPERIMENT_NAME_RANKING = "ranking-1-0-0";

/**
 * Example of an experiment definition:
 *
 *     {
 *       id: "ranking-1-0-0",
 *       getRandomizedGroup: () => {
 *         const B_VARIANT_CHANCE = 0.3;
 *         const C_VARIANT_CHANCE = 0.2;
 *         const variant = Math.random();

 *         switch (true) {
 *           case variant <= B_VARIANT_CHANCE:
 *             return "B";
 *           case variant <= B_VARIANT_CHANCE + C_VARIANT_CHANCE:
 *             return "C";
 *           default:
 *             return "A";
 *         }
 *       },
 *     },
 */
const ACTIVE_EXPERIMENTS = [
  {
    id: EXPERIMENT_NAME_RANKING,
    getRandomizedGroup: () => {
      const B_VARIANT_CHANCE = 0.3;
      const C_VARIANT_CHANCE = 0.2;
      const variant = Math.random();

      switch (true) {
        case variant <= B_VARIANT_CHANCE:
          return "B";
        case variant <= B_VARIANT_CHANCE + C_VARIANT_CHANCE:
          return "C";
        default:
          return "A";
      }
    },
  },
];

// 1. A middleware to get the active experiments
// 1.1 get the active experiments from cookies and attach that to req object, like feature flags
// 1.2 if nothing in the cookie, assign an experiment
export const experimentsMiddleware = (req, res, next) => {
  // Cookie shape:
  // { experimentId: experimentAnalyticsID:variationId }
  const userExperimentCookie = req.cookies[EXPERIMENT_COOKIE_KEY] || "{}";

  // TODO: test server does not crash when the experiment cookie is not here
  // especially because of the JSON parsing
  const experimentCookie = updateCookie(
    JSON.parse(userExperimentCookie),
    ACTIVE_EXPERIMENTS,
  );

  const expirationDate = new Date(2040, 1, 1);
  res.cookie(EXPERIMENT_COOKIE_KEY, JSON.stringify(experimentCookie), {
    expires: expirationDate,
    secure: isSecureCookie,
  });

  req.experiments = experimentCookie;

  next();
};

// 2. A react context with a custom hook to get the current experiment
const ExperimentContext = createContext({});
const providerPropTypes = {
  experiments: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export const ExperimentContextProvider = ({ experiments = {}, children }) => (
  <ExperimentContext.Provider value={{ experiments }}>
    {children}
  </ExperimentContext.Provider>
);
ExperimentContextProvider.propTypes = providerPropTypes;

export function useExperiments() {
  return useContext(ExperimentContext);
}

export function withExperiments(Component) {
  function WrappedComponent(props) {
    const ExperimentsContextValue = useExperiments();
    return <Component {...props} {...ExperimentsContextValue} />;
  }

  // Assign static properties like propTypes:
  for (const key of Object.keys(Component)) {
    WrappedComponent[key] = Component[key];
  }

  WrappedComponent.displayName = `WithExperiments(${getDisplayName(
    Component,
  )})`;

  return WrappedComponent;
}

/**
 * Assign the client to an experiment variant if it has not beed done already,
 * & Purges inactive exeperiments from the client cookie.
 * @param {*} cookie The active cookie in the client,
 * it's a key-value-pair store with the following shape: `{ experimentId: variationId }`
 * @param {*} experiments The active experiments in the application
 * @returns An dictionary of experiments { HumanReadableId: version, ... }
 */
export function updateCookie(cookie = {}, experiments = []) {
  const experimentCookie = { ...cookie };

  const userExperimentsIds = Object.keys(cookie);
  const experimentIds = experiments.map((experiment) => experiment.id);

  experiments.forEach((experiment) => {
    if (!userExperimentsIds.includes(experiment.id)) {
      // the experiment is not in the user cookie, add it
      const variant = experiment.getRandomizedGroup();
      experimentCookie[experiment.id] = variant;
    }
  });

  userExperimentsIds.forEach((userExperimentsId) => {
    if (!experimentIds.includes(userExperimentsId)) {
      delete experimentCookie[userExperimentsId];
    }
  });

  return experimentCookie;
}
