/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import {
  bookingOffers as bookingOffersConstants,
  listingRequests as listingRequestsConstants,
} from "@wunderflats/constants";
import omit from "lodash/omit";
import { nanoid } from "nanoid";
import {
  funnelKey,
  listingsSearchResultClickPlacement,
  schemas,
} from "./constants";

const { ADULT_RELATIONSHIPS } = listingRequestsConstants;
const { REASON_FOR_STAY } = bookingOffersConstants;

/**
 * @description gets the funnel id
 * @param {string} funnelKey
 * @returns {string} The funnel id
 */
export const getFunnelId = (funnelKey) => {
  return sessionStorage.getItem(funnelKey);
};

/**
 * @description removes the funnel id
 * @param {string} funnelKey
 */
export const removeFunnelId = (funnelKey) => {
  sessionStorage.removeItem(funnelKey);
};

/**
 * @description generates a funnel id
 * @param param.funnelKey {string} the funnel key
 * @param param.onlyGenerateIfNotExist {boolean} generates a new funnel-id if one does not already exist
 * @returns {string} The funnel id
 */
export const generateFunnelId = ({ funnelKey, onlyGenerateIfNotExist }) => {
  if (!funnelKey)
    throw new Error(
      "You must pass a valid funnel key which must be of type [string]",
    );

  if (onlyGenerateIfNotExist && getFunnelId(funnelKey)) {
    return getFunnelId(funnelKey);
  }

  const funnelId = nanoid();
  sessionStorage.setItem(funnelKey, funnelId);

  return funnelId;
};

/* ======= Request to book events ======= */
export const trackRequestToBookStep2Adapter = ({
  formData: { adultsInformation, childrenInformation, petsInformation },
  proceedToNextStep,
  formErrors,
}) => {
  const main_tenant =
    adultsInformation.find(
      (adult) => adult.adultRelationship === ADULT_RELATIONSHIPS.MainTenant,
    )?.adultName ?? null;

  const additional_adults = adultsInformation.reduce((acc, adult) => {
    if (adult.adultRelationship !== ADULT_RELATIONSHIPS.MainTenant) {
      acc.push({
        additional_adult_name: adult.adultName,
        additional_adult_relationship: adult.adultRelationship,
      });
    }

    return acc;
  }, []);

  const pet = (petsInformation || []).map((pet) => ({
    pet_type: pet.petName,
  }));

  const child = (childrenInformation || []).map((child) => ({
    age_bracket: child.childType,
  }));

  const {
    mainTenantNotAvailable = false,
    maxTenantExceeded = false,
    minTenantRequired = false,
  } = formErrors?.adultsInformation || {};

  return {
    main_tenant,
    additional_adults,
    pet,
    child,
    proceed_to_next_step: proceedToNextStep,
    funnel_id: getFunnelId(funnelKey.REQUEST_TO_BOOK),
    error_main_tenant: mainTenantNotAvailable,
    error_max_tenant: maxTenantExceeded,
    error_min_tenant: minTenantRequired,
  };
};

export const trackRequestToBookStep3Adapter = ({
  formData,
  proceedToNextStep,
  formErrors,
}) => {
  const {
    companyName,
    employmentType,
    jobTitle,
    reasonToStay,
    studyProgram,
    university,
    department,
  } = formData;

  let studyInformation = null;
  let workInformation = null;

  if (reasonToStay === REASON_FOR_STAY.Studying) {
    studyInformation = {
      university: university || null,
      study_program: studyProgram || null,
    };
  } else {
    workInformation = {
      employment_type: employmentType || null,
      job_title: jobTitle || null,
      company_name: companyName || null,
      company_department: department || null,
    };

    let omittedFields = ["company_department"];
    if (reasonToStay === REASON_FOR_STAY.Apprenticeship) {
      omittedFields = ["employment_type", "job_title", "company_department"];
    } else if (reasonToStay === REASON_FOR_STAY.Internship) {
      omittedFields = ["employment_type", "job_title"];
    }

    workInformation = omit(workInformation, omittedFields);
  }

  const requiredErrorSeen = Object.keys(formErrors).reduce((acc, field) => {
    return Boolean(formErrors[field].requiredErrorSeen) || acc;
  }, false);

  return {
    reason_for_stay: reasonToStay,
    work_information: workInformation,
    study_information: studyInformation,
    who_pays_rent: formData.paidBy,
    reason_for_stay_explained: formData.notes || null,
    country_code: formData.phone.split(" ")[0],
    phone_number: formData.phone,
    funnel_id: getFunnelId(funnelKey.REQUEST_TO_BOOK),
    proceed_to_next_step: proceedToNextStep,
    error_fill_in: requiredErrorSeen,
  };
};

/* ======= Listing search result click ======= */

export const getItemRank = ({ pageSize, itemIndexOnPage, pageNumber }) => {
  return pageSize * (pageNumber - 1) + (itemIndexOnPage + 1);
};

export const trackListingsSearchResultClickAdapter = ({
  pageNumber,
  clickPlacement,
  pageSize,
  itemIndexOnPage,
  total,
}) => {
  const isClickPlacementMap =
    clickPlacement === listingsSearchResultClickPlacement.MAP;

  return {
    page_number: isClickPlacementMap ? null : pageNumber,
    search_rank: isClickPlacementMap
      ? null
      : getItemRank({ pageSize, itemIndexOnPage, pageNumber }),
    click_placement: clickPlacement,
    results_count: isClickPlacementMap ? null : total,
  };
};

/* ======= Create Listing ======= */

export const trackCreateListingStep3Adapter = ({
  formData,
  proceedToNextStep,
  formErrors: { maximumUploadSizeReached, unSupportedFileType, unknownError },
}) => {
  const totalPhotoSize = formData.files.reduce(
    (acc, file) => acc + (file.size || 0),
    0,
  );

  return {
    photo_count: formData?.files?.length || 0,
    total_photos_size: totalPhotoSize / 1000,
    proceed_to_next_step: proceedToNextStep,
    funnel_id: getFunnelId(funnelKey.CREATE_LISTING),
    error_size: maximumUploadSizeReached,
    error_filetype: unSupportedFileType,
    error_unknown: unknownError,
  };
};

/* ======= Tenant account completion ======= */

export const tenantAccountCompletionSchema = ({
  isProfileComplete,
  areRequiredDocumentsUploaded,
}) => {
  let schema;

  if (!isProfileComplete && !areRequiredDocumentsUploaded) {
    schema = schemas.incompleteAccount;
  } else if (!isProfileComplete) {
    schema = schemas.incompleteProfile;
  } else if (!areRequiredDocumentsUploaded) {
    schema = schemas.incompleteDocuments;
  }

  return schema;
};

/* ======= Search tracking ======= */

const SEARCH_FILTER_KEYS_MAP = {
  from: "from_date",
  to: "to_date",
  minPrice: "price_min",
  maxPrice: "price_max",
  noDeposit: "deposit_required",
  minAccommodates: "number_of_people",
  amenities: "amenities",
  minRooms: "number_of_rooms",
  petRules: "pets_allowed",
  smokingRules: "smoking_allowed",
  homeType: "accommodation_type",
  minSize: "apartment_size",
  totalSingleBeds: "total_single_beds",
  totalDoubleBeds: "total_double_beds",
  categoryLabel: "collection_id",
  bbox: "bbox",
};

export const bboxToListConverter = (value) => {
  return decodeURIComponent(value)
    .split(";")
    .map((value) => value.split(","))
    .flat()
    .map((value) => parseFloat(value));
};

// Some fields require specific data manipulation,
// thus we have this list
const SEARCH_FILTER_KEYS_CONVERTER = {
  noDeposit: (value) => {
    if (!value) {
      return false;
    }

    return value === "true";
  },

  /**
   * Converts a comma-separated string into a list
   *
   * Example:
   *  >>> "a,b,c"
   *  ['a', 'b', 'c']
   */
  amenities: (value) => {
    return value?.split(",");
  },

  minAccommodates: (value) => Number.parseInt(value, 10),
  minPrice: (value) => Number.parseInt(value, 10),
  maxPrice: (value) => Number.parseInt(value, 10),
  minRooms: (value) => Number.parseInt(value, 10),
  minSize: (value) => Number.parseInt(value, 10),
  totalDoubleBeds: (value) => Number.parseInt(value, 10),
  totalSingleBeds: (value) => Number.parseInt(value, 10),

  petRules: (value) => value === "true",
  smokingRules: (value) => value === "true",

  /**
   * Convert a bounding box string in the format "x1,y1;x2,y2" into a list
   *
   * Example:
   *  >>> 7.647858602886647,51.979065108032465;7.668672544842213,51.96396893460527
   *  ["7.647858602886647", "51.979065108032465", "7.668672544842213", "51.96396893460527"]
   */
  bbox: (value) => bboxToListConverter(value),
};

export const trackFiltersAdapter = (filters) => {
  const appliedFilters = Object.keys(filters).reduce((acc, key) => {
    if (SEARCH_FILTER_KEYS_MAP[key]) {
      const converter = SEARCH_FILTER_KEYS_CONVERTER[key];
      acc[SEARCH_FILTER_KEYS_MAP[key]] = converter
        ? converter(filters[key])
        : filters[key];
    }

    return acc;
  }, {});

  return appliedFilters;
};
