import { countryDialCodes } from "./country-dial-codes";

const getFlagIcon = (countryCode) => {
  if (!countryCode) return "/public/images/planet.svg";

  return countryCode !== "US-CA"
    ? `https://wunderflatsng.blob.core.windows.net/artefacts/flag-icons/${countryCode.toLowerCase()}.svg`
    : "/public/images/us-ca-flag.svg";
};

export { getFlagIcon, countryDialCodes };
